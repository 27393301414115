import * as SentryBrowser from '@sentry/browser';
import * as SentryVue from '@sentry/vue';

const config = {
    // Only send errors and traces 25% of the time
    sampleRate: 0.25,
    tracesSampleRate: 0.25,
    dsn: window.sentry?.dsn,
    environment: window.sentry?.environment,
    ignoreErrors: ["Can't find variable: _AutofillCallbackHandler", 'Unable to preload CSS for'],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome-extension:\//i,
        /^chrome:\/\//i,
        // Firefox extensions
        /^resource:\/\//i,
    ],
};

export function initSentryBrowser() {
    if (window.sentry?.dsn) {
        SentryBrowser.init(config);
        trackImageLoadingErrors();
    }
}

export function initSentryVue(Vue) {
    if (window.sentry?.dsn) {
        SentryVue.init({ Vue: Vue, ...config });
    }
}

function trackImageLoadingErrors() {
    document.body.addEventListener(
        'error',
        (event) => {
            if (!event.target) return;

            if (event.target.tagName === 'IMG') {
                SentryBrowser.captureMessage(`Failed to load image: ${event.target.src}`, 'warning');
            } else if (event.target.tagName === 'LINK') {
                SentryBrowser.captureMessage(`Failed to load css: ${event.target.href}`, 'warning');
            }
        },
        true, // useCapture - necessary for resource loading errors
    );
}
